import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/Layout"
import rehypeReact from 'rehype-react'

import { MainWrapper } from '../components/Wrapper'
import { Container, SiteGrid } from '../components/Container'
import { SiteAnchor } from "../components/Link"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { PrimaryButton, ButtonIcon } from "../components/Button"
import { SectionHeader, BodyText, BlockContentQuote, BlockHeading, BlockContentParagraph, BlockContentUl, BlockContentOl, LiText } from "../components/Type"
import { color, space, fontSize, lineHeight, media, colorRgb } from "../styles/constants"

export const query = graphql`
    query(
        $slug: String!
    ){
        contentfulCaseStudy(
            slug:{
                eq: $slug
            }
        ){  
            heroImage{
                file{
                    url
                    contentType
                }
            }
            preview{
                preview
            }
            role{
                role
            }
            title
            body{
                raw
                references {
                    ... on ContentfulAsset {
                      contentful_id
                      __typename
                      description
                      title
                      file{
                          url
                      }
                    }
                }
            }
        }
        site{
            siteMetadata{
                pageTitleSuffix
            }
        }
    }
`

const CondensedSiteGrid = styled(SiteGrid)`
    grid-row-gap: 5vh;
`

const LiTextCondensed = styled(LiText)`
    p {
        margin: ${space.p50} 0;
    }
`

// Move all three headings to Type
const BlockHeading1 = styled.h1`
    font-family: 'Slabo 27px';
    font-size: ${fontSize.f350};
    color: ${color.white};
    font-weight: 400;
    line-height: ${lineHeight.lh350};
    margin: ${space.p400} 0 ${space.p100} 0;
`
const BlockHeading2 = styled.h2`
    font-family: 'IBM Plex Sans';
    font-size: ${fontSize.f250};
    color: ${color.white};
    font-weight: 600;
    line-height: ${lineHeight.lh250};
    margin: 0;
    margin: ${space.p400} 0 ${space.p100} 0;
`
const BlockHeading3 = styled.h3`
    font-family: 'IBM Plex Sans';
    font-size: ${fontSize.f175};
    color: ${color.white};
    font-weight: 600;
    line-height: ${lineHeight.lh175};
    margin: ${space.p400} 0 ${space.p100} 0;
`
const ProjectImage = styled.div`
    width: 100%;
    margin: ${space.p300} 0;
    padding: ${space.p200} 0;
    
`

const Subtext = styled(BodyText)`
    color: ${color.lightGrey};
    font-size: ${fontSize.f100};
    line-height: ${lineHeight.lh100};
    font-style: italic;
`

const HorizontalDivider = styled.hr`
    width: ${space.p500};
    border: rgba(${colorRgb.lightGrey}, 0.3) 1px dashed;
    margin: ${space.p500} auto;
`
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <BodyText>{children}</BodyText>,
        [BLOCKS.UL_LIST]: (node, children) => <BlockContentUl>{children}</BlockContentUl>,
        [BLOCKS.OL_LIST]: (node, children) => <BlockContentOl>{children}</BlockContentOl>,
        [BLOCKS.QUOTE]: (node, children) => <BlockContentQuote>{children}</BlockContentQuote>,
        [BLOCKS.HEADING_1]: (node, children) => <BlockHeading1>{children}</BlockHeading1>,
        [BLOCKS.HEADING_2]: (node, children) => <BlockHeading2>{children}</BlockHeading2>,
        [BLOCKS.HEADING_3]: (node, children) => <BlockHeading3>{children}</BlockHeading3>,
        [BLOCKS.LIST_ITEM]: (node, children) => <LiTextCondensed>{children}</LiTextCondensed>,
        [BLOCKS.HR]: (node, children) => <HorizontalDivider />,
        [INLINES.HYPERLINK]: (node, children) => {
            return(<SiteAnchor href={node.data.uri}>{children}</SiteAnchor>)
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const resolvedFileUrl = 'https:' + node.data.target.file.url
            console.log(resolvedFileUrl)
            // if (!gatsbyImageData) {
                // asset is not an image
            //     return null
            //   }

            // <img src={node.data.target.gatsbyImageData.images.fallback.src} alt={node.data.target.title} />
              return (
                  <ProjectImage>
                      <img src={resolvedFileUrl} alt={node.data.target.title} />
                      <Subtext><strong>Description: </strong>{node.data.target.description}</Subtext>
                  </ProjectImage>
              )
        }
    }
}

const PostHeading = styled.h2`
    color: ${color.white};
    font-family: 'IBM Plex Sans', sans-serif;
`

const PostContentArea = styled(Container)`
`

const FrontMatterArea = styled.div`
    text-align: left;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
    `}

    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 12;
    `}
`

const PostBodyArea = styled.div`
    text-align: left;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
    `}

    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 12;
    `}
`

const DateText = styled(BodyText)`
    color: ${color.lightGrey};
    opacity: 0.5;
    margin-top: ${space.p50};
`

const PreviewText = styled(BodyText)`
    font-style: italic;
    color: ${color.lightGrey};
`
const RoleText = styled(BodyText)`
    color: ${color.acYellow};
`

const PageHeader = styled(SectionHeader)`
    text-align: center;
`
const HeroImageContainer = styled(Container)``

const HeroImage = styled.div`
    overflow: hidden;
    vertical-align: middle;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 12;
    `}

    > {
        width: 100%;
        height: auto;
    }
`

const ScheduleCallArea = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    `}

    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 12;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    `}
`
const ScheduleCallContent = styled(BodyText)`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.sm`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
`

const ScheduleCallCta = styled.div`
    margin: ${space.p50} 0;
    // full-width for xs screens
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 4;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 4;
    `}
`

const WorkTemplatePage = (props) => {
    const pageTitle = props.data.contentfulCaseStudy.title + props.data.site.siteMetadata.pageTitleSuffix
    
    // to set lottie to a non-null value
    // exclusive to work pages so that the head is initialized only for these pages

    const lottie = 1
    return (
        <Layout title={pageTitle} lottie={lottie}>
            <MainWrapper />
            <CondensedSiteGrid>
                <Header />
                <HeroImageContainer>
                    <HeroImage>
                        {   // if Lottie JSON is uploaded as Hero
                            (props.data.contentfulCaseStudy.heroImage.file.contentType == "application/json") &&
                                <lottie-player src={props.data.contentfulCaseStudy.heroImage.file.url}  background="transparent"  speed="0.5"  loop  autoplay></lottie-player>
                        }
                        {   // if image is uploaded as Hero
                            (props.data.contentfulCaseStudy.heroImage.file.contentType != "application/json") &&
                                <img src={props.data.contentfulCaseStudy.heroImage.file.url} />
                        }
                    </HeroImage>
                </HeroImageContainer>
                <PostContentArea>
                    <FrontMatterArea>
                        <SectionHeader>Case Study</SectionHeader>
                        <PostHeading>{props.data.contentfulCaseStudy.title}</PostHeading>
                        <PreviewText>{props.data.contentfulCaseStudy.preview.preview}</PreviewText>
                        <RoleText><strong>Role: </strong>{props.data.contentfulCaseStudy.role.role}</RoleText>
                    </FrontMatterArea>

                    <PostBodyArea>{
                        renderRichText(props.data.contentfulCaseStudy.body, options)
                    }
                    </PostBodyArea>
                    <ScheduleCallArea>
                        <ScheduleCallContent>
                            For a deeper dive into any of my case studies, set up a 30-minute call.
                        </ScheduleCallContent>
                        <ScheduleCallCta>
                            <a
                                href="https://calendly.com/skepticacid/introduction"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <PrimaryButton>Set up a call<ButtonIcon className="fas fa-external-link-alt"></ButtonIcon></PrimaryButton>
                            </a>
                        </ScheduleCallCta>
                    </ScheduleCallArea>
                </PostContentArea>
                <Footer />
            </CondensedSiteGrid>
        </Layout>
    )
}

export default WorkTemplatePage

/*

,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const { gatsbyImageData } = node.data.target
            console.log(node.data.target)
            if (!gatsbyImageData) {
                // asset is not an image
                return null
              }
              return (
                  <ProjectImage>
                      <img src={node.data.target.gatsbyImageData.images.fallback.src} />
                  </ProjectImage>
              )
        }

*/